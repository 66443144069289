<template>
<b-card class="w-100 mb-4" no-body>
    <div class="p-35">
        <div class="d-flex align-items-start">
            <div>
                <h4 class="card-title mb-1">Update Job</h4>
                <b-card-text class="text-muted">
                </b-card-text>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
        <b-row v-if="authUserType === '1'" class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium"> Assigned Inspector </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-select :options="inspectors" v-model="form.registration"></b-form-select></b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium"> Score </label>
            </b-col>
            <b-col sm="9">
                <b-form-group><b-form-input type="text" v-model="form.success_score" placeholder="5" required></b-form-input></b-form-group>
            </b-col>
        </b-row>
        <b-row class="py-3 border-bottom align-items-center">
            <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium"> Attach Report </label>
            </b-col>
            <b-col sm="4">
                <b-form-file v-model="form.certificate" id="file"></b-form-file>
            </b-col>
            <b-col sm="2">
                <b-spinner v-if="uploading" label="Spinning"></b-spinner>
                <b-button v-else pill variant="success" class="" @click="uplaodFile()">Upload</b-button>
            </b-col>
            <b-col sm="3">
                <span>{{ currentJob.certificate!=="none"? currentJob.certificate:"No Report Found" }}</span>
            </b-col>
        </b-row>
    </b-card-body>
    <div class="p-35 border-top">
        <div class="btn-grp text-right">
            <b-button pill variant="primary" class="px-4" @click="updateJob()">Save</b-button>
            <b-button pill variant="danger" class="px-4">Cancel</b-button>
        </div>
    </div>
</b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "Update_Job",
  data: () => ({
      uploading: false,
      currentJob: null,
      inspectors: [],
      form: {
          registration: null,
          success_score: 5,
          certificate: "none",
          inspection_status: 1,
      }
  }),
  components: {},
  computed:{
      ...mapState({
          authId: (state) => state.auth.authId,
          authUserType: (state) => state.auth.authUserType
      })
  },
  mounted() {
    this.loadjob();
  },
  methods:{
    ...mapActions(["getUsers","getInspectionJob", "updateInspectionJob", "saveFile"]),
    loadjob(){
      this.getInspectionJob({id:this.$route.params.id}).then(res => {
        this.currentJob = res[0];
      })
      this.getUsers().then(res => {
        let items = res.filter(u => u.user_type === "5");
        items.forEach(element => {
            this.inspectors.push({text: element.full_name, value: element.id})
        });
      })
    },
    updateJob(){
        this.currentJob.certificate = this.form.certificate;
        this.currentJob.success_score = this.form.success_score;
        this.currentJob.inspection_status = this.form.inspection_status;
        this.currentJob.registration = this.form.registration;
        this.updateInspectionJob(this.currentJob).then((res) => {
            if('status' in res && res.status === 200){
                this.$bvToast.toast('Inspection Job Updated', {
                    title: "Success",
                    variant: 'success',
                    solid: true
                })
            }
        })
    },
    uplaodFile(){
        this.uploading = true; 
        const formData = new FormData();
        formData.append("file", this.form.certificate);
        this.saveFile(formData).then((res) => {
            if(res.status === 200){
                this.form.certificate = res.data;
                this.uploading = false;
            }
        })
    },
  }
};
</script>
